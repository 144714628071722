import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { countyKeys } from "reactQuery/keys";

import { Demographic } from "./apiTypes";

export const useGetCountyDemographicsByQuery = (
    query: string,
    enabled: boolean
): UseQueryResult<Demographic[]> => {
    const queryKey = countyKeys.search(query);
    return useQuery({
        queryKey,
        queryFn: async (): Promise<Demographic[]> =>
            (
                await nautilusGetRequest(
                    "/api/universe/county_demographics/",
                    `?limit=100&name=${query}`
                )
            ).data?.results,
        enabled,
    });
};
