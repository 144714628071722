import { FormInstance } from "antd";

import {
    BlankEnum,
    ConstraintNameEnum,
    ConstraintTypeEnum,
    FilterTypeEnum,
    Profile,
    SearchPlanFormIntelligenceScopeDetail,
} from "reactQuery/hooks/apiTypes";

export const setFormValues = (
    form: FormInstance,
    intelligenceScopeDetail: SearchPlanFormIntelligenceScopeDetail
) => {
    form.setFieldsValue({
        rank: intelligenceScopeDetail?.rank,
        additional_notes: intelligenceScopeDetail?.additional_notes,
    });

    if (intelligenceScopeDetail?.indicator) {
        form.setFieldsValue({
            indicator: [
                intelligenceScopeDetail?.indicator?.industry_id,
                intelligenceScopeDetail?.indicator?.category_id,
                intelligenceScopeDetail?.indicator?.id,
            ],
        });
    }

    const {
        country_constraints: countryConstraints,
        state_constraints: stateConstraints,
        county_constraints: countyConstraints,
        owner_constraints: ownerConstraints,
        owner_type_constraints: ownerTypeConstraints,
        population_constraints: populationConstraints,
        initiativetype_constraints: initiativeTypeConstraints,
        timing_status_constraints: timingStatusConstraints,
        indicatortag_constraints: indicatorTagConstraints,
        project_budget_constraints: projectBudgetConstraints,
        source_timeframe_constraints: sourceTimeframeConstraints,
        mgd_constraints: mgdConstraints,
        display_start_date_constraints: displayStartDateConstraints,
    } = intelligenceScopeDetail;

    const setConstraintValues = ({
        constraintName,
        id,
        constraintType,
        filterType,
        profiles,
        includeNull,
    }: {
        constraintName: ConstraintNameEnum;
        id: number;
        constraintType: ConstraintTypeEnum | BlankEnum;
        filterType: FilterTypeEnum | BlankEnum;
        profiles: Profile[];
        includeNull: boolean;
    }): void => {
        form.setFieldValue(
            [constraintName, `constraint_id_${id}`, "constraint_type"],
            constraintType ?? "Include"
        );
        form.setFieldValue(
            [constraintName, `constraint_id_${id}`, "filter_type"],
            filterType
        );
        form.setFieldValue(
            [constraintName, `constraint_id_${id}`, "profiles"],
            profiles.map((profile: Profile) => ({
                value: profile.id,
                label: `${profile.user.first_name} ${profile.user.last_name} (${profile.team.name})`,
            }))
        );
        form.setFieldValue(
            [constraintName, `constraint_id_${id}`, "include_null"],
            includeNull
        );
    };

    countryConstraints.forEach(
        ({ id, countries, constraint_type, filter_type, profiles, include_null }) => {
            form.setFieldValue(
                [ConstraintNameEnum.Countries, `constraint_id_${id}`, "countries"],
                countries.map((country) => country.id)
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.Countries,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    stateConstraints.forEach(
        ({ id, states, constraint_type, filter_type, profiles, include_null }) => {
            form.setFieldValue(
                [ConstraintNameEnum.States, `constraint_id_${id}`, "states"],
                states.map((state) => state.id)
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.States,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    countyConstraints.forEach(
        ({
            id,
            demographics,
            constraint_type,
            filter_type,
            profiles,
            include_null,
        }) => {
            form.setFieldValue(
                [ConstraintNameEnum.Counties, `constraint_id_${id}`, "demographics"],
                demographics.map((demographic) => ({
                    value: demographic.id,
                    label: `${demographic.name} (${demographic.state_code})`,
                }))
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.Counties,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    ownerConstraints.forEach(
        ({ id, owners, constraint_type, filter_type, profiles, include_null }) => {
            form.setFieldValue(
                [ConstraintNameEnum.Owners, `constraint_id_${id}`, "owners"],
                owners.map((owner) => ({
                    value: owner.id,
                    label: `${owner.legal_name} | ${owner.state_code} | ${owner.whid}`,
                }))
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.Owners,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    ownerTypeConstraints.forEach(
        ({ id, owner_types, constraint_type, filter_type, profiles, include_null }) => {
            form.setFieldValue(
                [ConstraintNameEnum.OwnerTypes, `constraint_id_${id}`, "owner_types"],
                owner_types.map((ownerType) => ({
                    value: ownerType.id,
                    label: ownerType.owner_type,
                }))
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.OwnerTypes,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    populationConstraints.forEach(
        ({
            id,
            minimum,
            maximum,
            constraint_type,
            filter_type,
            profiles,
            include_null,
        }) => {
            form.setFieldValue(
                [ConstraintNameEnum.Population, `constraint_id_${id}`, "minimum"],
                minimum
            );
            form.setFieldValue(
                [ConstraintNameEnum.Population, `constraint_id_${id}`, "maximum"],
                maximum
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.Population,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    initiativeTypeConstraints.forEach(
        ({
            id,
            initiative_types,
            constraint_type,
            filter_type,
            profiles,
            include_null,
        }) => {
            form.setFieldValue(
                [
                    ConstraintNameEnum.InitiativeTypes,
                    `constraint_id_${id}`,
                    "initiative_types",
                ],
                initiative_types.map((initiativeType) => initiativeType.id)
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.InitiativeTypes,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    timingStatusConstraints.forEach(
        ({ id, statuses, constraint_type, filter_type, profiles, include_null }) => {
            form.setFieldValue(
                [ConstraintNameEnum.TimingStatuses, `constraint_id_${id}`, "statuses"],
                statuses.map((timingStatus) => timingStatus.id)
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.TimingStatuses,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    indicatorTagConstraints.forEach(
        ({
            id,
            indicator_tags,
            constraint_type,
            filter_type,
            profiles,
            include_null,
        }) => {
            form.setFieldValue(
                [
                    ConstraintNameEnum.IndicatorTags,
                    `constraint_id_${id}`,
                    "indicator_tags",
                ],
                indicator_tags.map((indicatorTag) => ({
                    value: indicatorTag.id,
                    label: indicatorTag.value,
                }))
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.IndicatorTags,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    projectBudgetConstraints.forEach(
        ({
            id,
            minimum,
            maximum,
            constraint_type,
            filter_type,
            profiles,
            include_null,
        }) => {
            form.setFieldValue(
                [ConstraintNameEnum.ProjectBudget, `constraint_id_${id}`, "minimum"],
                minimum
            );
            form.setFieldValue(
                [ConstraintNameEnum.ProjectBudget, `constraint_id_${id}`, "maximum"],
                maximum
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.ProjectBudget,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    sourceTimeframeConstraints.forEach(
        ({ id, maximum, constraint_type, filter_type, profiles, include_null }) => {
            form.setFieldValue(
                [
                    ConstraintNameEnum.SourceDocumentDate,
                    `constraint_id_${id}`,
                    "maximum",
                ],
                maximum
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.SourceDocumentDate,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    mgdConstraints.forEach(
        ({
            id,
            minimum,
            maximum,
            constraint_type,
            filter_type,
            profiles,
            include_null,
        }) => {
            form.setFieldValue(
                [ConstraintNameEnum.DesignFlowMgd, `constraint_id_${id}`, "minimum"],
                minimum
            );
            form.setFieldValue(
                [ConstraintNameEnum.DesignFlowMgd, `constraint_id_${id}`, "maximum"],
                maximum
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.DesignFlowMgd,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
    displayStartDateConstraints.forEach(
        ({
            id,
            display_start_date,
            constraint_type,
            filter_type,
            profiles,
            include_null,
        }) => {
            form.setFieldValue(
                [
                    ConstraintNameEnum.DisplayStartDate,
                    `constraint_id_${id}`,
                    "display_start_date",
                ],
                display_start_date
            );
            setConstraintValues({
                constraintName: ConstraintNameEnum.DisplayStartDate,
                id,
                constraintType: constraint_type,
                filterType: filter_type,
                profiles,
                includeNull: include_null,
            });
        }
    );
};
