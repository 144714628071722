import { Form, Input } from "antd";

const AdditionalNotesInput = ({}) => {
    return (
        <Form.Item style={{ margin: 0 }} name="additional_notes" preserve={false}>
            <Input data-testid="additional-notes-input" />
        </Form.Item>
    );
};

export default AdditionalNotesInput;
