import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { FEED_TYPES } from "constants/feedTypes";
import { IntelligenceReportsSingleOpportunityPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleOpportunity";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useUpdateIPLResults } from "reactQuery/hooks/useIPLResults";
import {
    useGetIPLResultStatusChoices,
    useGetIPLResultAssigneeActionedStatusChoices,
} from "reactQuery/hooks/useTableAssociatedData";
import {
    useGetTableData,
    useGetTableFilters,
    useGetTableItemIds,
} from "reactQuery/hooks/useTableData";
import { IndicatorFeedsSingleOpportunityPathname } from "routes/IndicatorFeeds";
import {
    AllFeedbackSingleFeedbackPathname,
    AllFeedbackSingleOpportunityPathname,
    AllIndicatorsSingleOpportunityPathname,
    AssignedInsightsSingleAssigneePathname,
    AssignedInsightsSingleOpportunityPathname,
    AllInsightsSingleOpportunityPathname,
    AllOwnersSingleOpportunityPathname,
    MyInsightsSingleOpportunityPathname,
    PriorityInsightsSingleOpportunityPathname,
    AllOwnersSingleOwnerPathname,
    AllIndicatorsSingleIndicatorPathname,
} from "routes/IntelligenceFeeds";
import {
    queryParamBuilder,
    hasCustomParams,
    queryObjBuilder,
} from "utils/useManagedQueryParams";

import emptyData from "./emptyData";
import { FILTER_TYPES, filterTypesType, FILTER_TEMPLATES } from "./options";
import { allowFiltersQueryLoad, getDateXDaysAgo } from "./utils";
interface tableOptionsType {
    loadFilters: filterTypesType;
    [key: string]: any;
}

export const useTableDependencies = ({
    contentId,
    feedType,
    filtersEnabled,
    teamId,
}) => {
    const history = useHistory();
    const [syncedDefaultFilters, setSyncedDefaultFilters] = useState<boolean>(false);
    const [waitForDefaultFilters, setWaitForDefaultFilters] = useState<boolean>(true);
    const getWhoamiQuery = useGetWhoami();
    const { search } = useLocation();
    const excludeQueryParams = [
        "feedback",
        "indicator_id",
        "opportunity_id",
        "owner_id",
        "user_id",
    ];
    const singleAssigneePathParams = {
        dataKey: "id",
        excludeQueryParams,
        path: AssignedInsightsSingleAssigneePathname,
        pathQueryParam: "user_id",
        setQueryParam: true,
    };
    const singleFeedbackPathParams = {
        dataKey: "feedback_name",
        excludeQueryParams,
        path: AllFeedbackSingleFeedbackPathname,
        pathQueryParam: "feedback",
        setQueryParam: true,
    };
    const singleIndicatorPathParams = {
        dataKey: "id",
        excludeQueryParams,
        path: AllIndicatorsSingleIndicatorPathname,
        pathQueryParam: "indicator_id",
        setQueryParam: true,
    };
    const singleOwnerPathParams = {
        dataKey: "id",
        excludeQueryParams,
        path: AllOwnersSingleOwnerPathname,
        pathQueryParam: "owner_id",
        setQueryParam: true,
    };
    const viewOpportunityButtonCommonParams = {
        dataKey: "opportunity_id",
        includeAllQueryParams: true,
        pathQueryParam: "opportunity_id",
    };

    const tableOptions: Record<string, tableOptionsType> = {
        [FEED_TYPES.ALL_FEEDBACK]: {
            clickParams: {
                viewButton: singleFeedbackPathParams,
            },
            columns: [
                "feedback_name",
                "totalOpportunityValueNoSorting",
                "insightCountNoSorting",
                "viewButton",
            ],
            emptyDataDisplay: emptyData(
                "Oops, you haven't provided any feedback yet. To take advantage of this feature, simply provide feedback or log progress on any opportunity.",
                "Have questions? contact"
            ),
            loadFilters: FILTER_TYPES.DISABLED,
            recordTitle: "Feedback",
            recordTitlePlural: "Feedback",
        },
        [FEED_TYPES.ALL_ASSIGNEES]: {
            clickParams: {
                viewButton: singleAssigneePathParams,
            },
            columns: [
                "salesManager",
                "assignee",
                "totalOpportunityValueNoSorting",
                "insightCount",
                "viewButton",
            ],
            emptyDataDisplay: emptyData(
                "Oops, you haven't assigned any insights yet. To take advantage of this feature, simply assign opportunities to your team",
                "or contact"
            ),
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Assignee",
            showRecordsCount: true,
        },
        [FEED_TYPES.ALL_INDICATORS]: {
            clickParams: {
                viewButton: singleIndicatorPathParams,
            },
            columns: [
                "market",
                "allIndicatorsIndicator",
                "rank",
                "totalOpportunityValueNoSorting",
                "insightCount",
                "viewButton",
            ],
            dataCacheStaleTime: 5 * 60 * 1000, // 5min
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Indicator",
            showRecordsCount: true,
        },
        [FEED_TYPES.ALL_INSIGHTS]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: IntelligenceReportsSingleOpportunityPathname,
                    setQueryParam: true,
                },
            },
            columns: [
                "reportDate",
                "status",
                "state",
                "opportunityOwnerString",
                "indicators",
                "summary",
            ],
            expandableRow: "insight",
            loadFilters: FILTER_TYPES.STAFF_EXPLICIT_LOAD,
            recordTitle: "Insight",
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.ALL_OWNERS]: {
            clickParams: {
                viewButton: singleOwnerPathParams,
            },
            columns: [
                "ownerName",
                "rank",
                "state",
                "ownerPopulation",
                "fiscalStart",
                "totalOpportunityValueNoSorting",
                "insightCount",
                "viewButton",
            ],
            dataCacheStaleTime: 5 * 60 * 1000, // 5min
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Owner",
            showRecordsCount: true,
        },
        [FEED_TYPES.ALL_TEAM_INSIGHTS]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AllInsightsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            expandableRow: "insight",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.INDICATOR_FEED]: {
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: IndicatorFeedsSingleOpportunityPathname,
                    setQueryParam: true,
                },
            },
            columns: [
                "displayDate",
                "status",
                "state",
                "opportunityOwnerString",
                "indicators",
                "triggerExtract",
            ],
            expandableRow: "insight",
            insightTagTitle: "Indicator Feed",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.MY_INSIGHTS]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: MyInsightsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            emptyDataDisplay: emptyData(
                "Oops, you have not yet been assigned to any insights. To take advantage of this feature, please contact your sales manager",
                "or"
            ),
            expandableRow: "insight",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.PRIORITY_INSIGHTS]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: PriorityInsightsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            expandableRow: "insight",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.LW_ALL_RESULTS]: {
            associatedData: ["ipl_result_status_choices", "table_item_ids"],
            bulkActionOptions: ["assign_ipl_results"],
            columns: [
                "ownerNameNoSorting",
                "indicatorSnippet",
                "longerSnippet",
                "lwStatus",
            ],
            dataCacheStaleTime: 15 * 60 * 1000, // 15min
            defaultPageSize: 50,
            defaultTableFilters: {
                date_created_after: getDateXDaysAgo(30),
                indicator_ids: [420],
                query_types: "Keyword",
                statuses: "To Be Confirmed",
            },
            defaultTableSorter: { field: "date_created", order: "descend" },
            expandableRow: "lw",
            filtersTemplate: FILTER_TEMPLATES.IPL_RESULTS,
            loadFilters: FILTER_TYPES.MODAL,
            pageSizeOptions:
                getWhoamiQuery.data?.user?.is_staff &&
                !getWhoamiQuery.data?.user?.is_datacore
                    ? [5, 10, 20, 50, 100, 500, 750]
                    : null,
            recordTitle: "IPL Result",
            recordTitlePlural: "IPL Results",
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.LW_MY_ACTIONED]: {
            associatedData: ["ipl_result_status_choices", "table_item_ids"],
            columns: [
                "ownerNameNoSorting",
                "indicatorSnippet",
                "longerSnippet",
                "lwStatus",
            ],
            dataCacheStaleTime: 5 * 60 * 1000, // 5min
            defaultPageSize: 50,
            defaultTableFilters: {
                assignee_ids: getWhoamiQuery.data?.user.id,
                date_assigned_after: getDateXDaysAgo(30),
            },
            defaultTableSorter: { field: "date_actioned", order: "descend" },
            expandableRow: "lw",
            filtersTemplate: FILTER_TEMPLATES.IPL_RESULTS,
            loadFilters: FILTER_TYPES.MODAL,
            recordTitle: "IPL Result",
            recordTitlePlural: "IPL Results",
            showRecordsCount: true,
        },
        [FEED_TYPES.LW_MY_QUEUE]: {
            associatedData: ["ipl_result_status_choices", "table_item_ids"],
            columns: [
                "ownerNameNoSorting",
                "indicatorSnippet",
                "longerSnippet",
                "lwStatus",
            ],
            dataCacheStaleTime: 5 * 60 * 1000, // 5min
            defaultPageSize: 50,
            defaultTableFilters: {
                assignee_ids: getWhoamiQuery.data?.user.id,
                statuses: "To Be Confirmed",
                date_assigned_after: getDateXDaysAgo(30),
            },
            defaultTableSorter: { field: "date_assigned", order: "descend" },
            expandableRow: "lw",
            filtersTemplate: FILTER_TEMPLATES.IPL_RESULTS,
            loadFilters: FILTER_TYPES.MODAL,
            recordTitle: "IPL Result",
            recordTitlePlural: "IPL Results",
            showRecordsCount: true,
        },
        [FEED_TYPES.LW_READY_FOR_QC]: {
            associatedData: [
                "ipl_result_assignee_actioned_status_choices",
                "ipl_result_status_choices",
                "table_item_ids",
            ],
            bulkActionOptions: ["assign_ipl_results"],
            columns: [
                "ownerNameNoSorting",
                "indicatorSnippet",
                "longerSnippet",
                "assigneeNoSorting",
                "lwStatusAndActionedStatus",
            ],
            dataCacheStaleTime: 5 * 60 * 1000, // 5min
            defaultPageSize: 50,
            defaultTableFilters: {
                date_assigned_after: getDateXDaysAgo(30),
                linked_insight: "True",
                review_statuses: "pending",
                statuses: "Confirmed Match",
            },
            defaultTableSorter: { field: "date_actioned", order: "descend" },
            expandableRow: "lw",
            filtersTemplate: FILTER_TEMPLATES.IPL_RESULTS,
            loadFilters: FILTER_TYPES.MODAL,
            recordTitle: "IPL Result",
            recordTitlePlural: "IPL Results",
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.SINGLE_ASSIGNEE]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AssignedInsightsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            emptyDataDisplay: emptyData(
                "Oops, you haven't assigned any insights to this user yet. To take advantage of this feature, simply assign opportunities to this user",
                "or contact"
            ),
            expandableRow: "insight",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            secondaryId: Number(new URLSearchParams(search).get("user_id")),
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.SINGLE_FEEDBACK]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AllFeedbackSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            expandableRow: "insight",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            secondaryId: new URLSearchParams(window.location.search).get("feedback"),
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.SINGLE_INDICATOR]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AllIndicatorsSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
                owner: singleOwnerPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "opportunityOwner",
                "indicators",
                "summary",
            ],
            expandableRow: "insight",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            secondaryId: Number(new URLSearchParams(search).get("indicator_id")),
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.SINGLE_OWNER]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: AllOwnersSingleOpportunityPathname,
                },
                indicator: singleIndicatorPathParams,
            },
            columns: [
                "displayDate",
                "priorityInsight",
                "state",
                "indicators",
                "summary",
            ],
            expandableRow: "insight",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            secondaryId: Number(new URLSearchParams(search).get("owner_id")),
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.SINGLE_REPORT]: {
            bulkActionOptions: ["share_opportunities", "sync_opportunities_crm"],
            clickParams: {
                expandableRowButton: {
                    ...viewOpportunityButtonCommonParams,
                    path: IntelligenceReportsSingleOpportunityPathname,
                    setQueryParam: true,
                },
            },
            columns: [
                "status",
                "state",
                "opportunityOwnerString",
                "population",
                "indicators",
                "summary",
            ],
            expandableRow: "insight",
            insightTagTitle: "Report",
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "Insight",
            showBulkActions: true,
            showRecordsCount: true,
        },
        [FEED_TYPES.TEAM_ACTIVITY]: {
            columns: [
                "userName",
                "userEmail",
                "userCreationDate",
                "userLastLoginDate",
                "userIsActive",
            ],
            loadFilters: FILTER_TYPES.DEFAULT,
            recordTitle: "User",
            recordTitlePlural: "Users",
            showRecordsCount: true,
        },
    };

    const queryObj = queryObjBuilder(
        null,
        null,
        tableOptions[feedType].defaultPageSize,
        true,
        tableOptions[feedType].pageSizeOptions
    );
    const currentPage = queryObj.paginatedInfo?.current || 1;

    useEffect(() => {
        // This useEffect hook will also be used to allow BE default parameters to load before table data to avoid redundant API calls
        // The reason window.location.search is checked is because the component is not reloaded if the sidebar entry is clicked again
        // Thus, the if the base URL is discovered, the default filters will be applied
        if (
            getWhoamiQuery.isSuccess &&
            ((waitForDefaultFilters && tableOptions[feedType]) ||
                window.location.search === `?team_id=${teamId}`)
        ) {
            if (
                tableOptions[feedType].defaultTableFilters ||
                tableOptions[feedType].defaultTableSorter
            ) {
                if (
                    hasCustomParams(
                        tableOptions[feedType].defaultTableFilters,
                        tableOptions[feedType].defaultTableSorter
                    )
                ) {
                    setWaitForDefaultFilters(false);
                    return;
                }
                if (syncedDefaultFilters) {
                    setSyncedDefaultFilters(false);
                }
                const queryParams = queryParamBuilder({
                    defaultPageSize: tableOptions[feedType].defaultPageSize,
                    includeBaseParams: true,
                    //includeDefaultPagination: true,
                    includeExistingParams: true,
                    includePagination: true,
                    filters: tableOptions[feedType].defaultTableFilters,
                    sorter: tableOptions[feedType].defaultTableSorter,
                });
                history.push({ search: queryParams });
            }
        }
        setWaitForDefaultFilters(false);
    }, [tableOptions]);

    const relevantId = [FEED_TYPES.INDICATOR_FEED, FEED_TYPES.SINGLE_REPORT].includes(
        feedType
    )
        ? contentId
        : teamId;

    const isStaff = getWhoamiQuery.isSuccess
        ? getWhoamiQuery.data.user.is_staff
        : false;

    const tableDataQuery = useGetTableData({
        feedType,
        defaultPageSize: tableOptions[feedType]?.defaultPageSize,
        enabled: !waitForDefaultFilters,
        staleTime: tableOptions[feedType]?.dataCacheStaleTime,
    });

    const loadFilters = tableOptions[feedType]?.loadFilters;
    const enableFiltersQuery = allowFiltersQueryLoad({
        loadFilters: loadFilters,
        isStaff: isStaff,
        filtersEnabled: filtersEnabled,
    });
    const tableFiltersQuery = useGetTableFilters(
        feedType,
        relevantId,
        tableOptions[feedType]?.secondaryId,
        enableFiltersQuery
    );

    const tableItemsQueriesEnabled =
        (tableOptions[feedType]?.associatedData?.includes("table_item_ids") &&
            !waitForDefaultFilters) ||
        false;
    const previousTableItemsQuery = useGetTableItemIds({
        feedType,
        defaultPageSize: tableOptions[feedType]?.defaultPageSize,
        requestedPage: currentPage - 1,
        enabled: tableItemsQueriesEnabled && currentPage > 1,
    });
    const currentTableItemsQuery = useGetTableItemIds({
        feedType,
        defaultPageSize: tableOptions[feedType]?.defaultPageSize,
        requestedPage: currentPage,
        enabled: tableItemsQueriesEnabled,
    });
    const nextTableItemsQuery = useGetTableItemIds({
        feedType,
        defaultPageSize: tableOptions[feedType]?.defaultPageSize,
        requestedPage: currentPage + 1,
        enabled: tableItemsQueriesEnabled,
    });
    const itemIdsLoading =
        (currentPage > 1 && previousTableItemsQuery.isLoading) ||
        currentTableItemsQuery.isLoading ||
        nextTableItemsQuery.isLoading;
    const itemIds = [
        ...new Set([
            ...(previousTableItemsQuery?.data?.results || []),
            ...(currentTableItemsQuery?.data?.results || []),
            ...(nextTableItemsQuery?.data?.results || []),
        ]),
    ];

    const associatedDataQueries = {
        ipl_result_assignee_actioned_status_choices:
            useGetIPLResultAssigneeActionedStatusChoices(
                tableOptions[feedType]?.associatedData?.includes(
                    "ipl_result_assignee_actioned_status_choices"
                ) || false
            ),
        ipl_result_status_choices: useGetIPLResultStatusChoices(
            tableOptions[feedType]?.associatedData?.includes(
                "ipl_result_status_choices"
            ) || false
        ),
    };

    const mutateDataQueries = {
        update_ipl_result_status: useUpdateIPLResults,
    };

    return {
        associatedDataQueries,
        dataQuery: tableDataQuery,
        defaultFilters: tableOptions[feedType].defaultTableFilters || {},
        defaultSorter: tableOptions[feedType].defaultTableSorter || {},
        enableFiltersQuery: enableFiltersQuery,
        filtersQuery: tableFiltersQuery,
        hasDefaultFilterSort:
            tableOptions[feedType].defaultTableFilters ||
            tableOptions[feedType].defaultTableSorter,
        itemIdPages: {
            previous: previousTableItemsQuery?.data?.results || [],
            current: currentTableItemsQuery?.data?.results || [],
            next: nextTableItemsQuery?.data?.results || [],
        },
        itemIds,
        itemIdsLoading,
        modalFilters: loadFilters === FILTER_TYPES.MODAL ? true : false,
        mutateDataQueries,
        relevantId,
        showLoadFiltersButton:
            loadFilters === FILTER_TYPES.EXPLICIT_LOAD
                ? !filtersEnabled
                    ? true
                    : false
                : loadFilters === FILTER_TYPES.STAFF_EXPLICIT_LOAD
                ? isStaff && !filtersEnabled
                    ? true
                    : false
                : false,
        syncedDefaultFilters,
        tableOptions: tableOptions[feedType],
        setSyncedDefaultFilters,
    };
};
