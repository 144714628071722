import { WarningFilled } from "@ant-design/icons";

export const IPLResultWarning = ({ warning }: { warning: any }) => {
    const match = warning.display_name.match(/Potential Duplicate: Insight (\d+)/);
    const insightNumber = match ? match[1] : null;
    let preInsightLinkText = "";
    let postInsightLinkText;
    if (insightNumber) {
        [preInsightLinkText, postInsightLinkText] = warning.display_name.split(
            `Insight ${insightNumber}`
        );
    }
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <WarningFilled style={{ color: "orange" }} />
            &nbsp;
            <div style={{ color: "orange" }}>
                {insightNumber ? (
                    <div>
                        {preInsightLinkText}
                        <a
                            href={`/intrpt/opportunity?team_id=${warning.insight?.team_id}&opportunity_id=${warning.insight?.opportunity_id}`}
                            target="_blank"
                        >
                            Insight {insightNumber}
                        </a>
                        {postInsightLinkText}
                    </div>
                ) : (
                    warning.display_name
                )}
            </div>
        </div>
    );
};
