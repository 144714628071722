import { Cascader, Form } from "antd";

import { useGetIndicatorCascader } from "reactQuery/hooks/useGetIndicatorCascader";

const IndicatorCascader = ({}) => {
    const indicatorCascaderQuery = useGetIndicatorCascader();

    const displayRender = (
        label: string[] | number[],
        selectedOptions: { label: string }[] | undefined[]
    ) => {
        if (Number.isInteger(label[0])) {
            return "Loading...";
        }
        return selectedOptions
            .map((option: { label: string }) => option?.label)
            .join(" > ");
    };

    return (
        <Form.Item
            style={{ margin: 0 }}
            name="indicator"
            rules={[
                {
                    required: true,
                    message:
                        "Please select an indicator by first choosing an industry, then category",
                },
            ]}
            preserve={false}
        >
            <Cascader
                displayRender={displayRender}
                options={
                    indicatorCascaderQuery.isLoading ? [] : indicatorCascaderQuery.data
                }
                placeholder="Select an indicator"
                showSearch={true}
                allowClear={false}
            />
        </Form.Item>
    );
};

export default IndicatorCascader;
