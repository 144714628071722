import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { nautilusGetRequest } from "network";
import { indicatorCascaderKeys } from "reactQuery/keys";

import { CascaderIndicatorIndustry } from "./apiTypes";

export const useGetIndicatorCascader = (
    enabled: boolean = true
): UseQueryResult<CascaderIndicatorIndustry[]> => {
    const queryKey = indicatorCascaderKeys.all();
    return useQuery({
        queryKey,
        queryFn: async (): Promise<CascaderIndicatorIndustry[]> =>
            (
                await nautilusGetRequest(
                    "/api/target_reports/industry_category_indicator_cascader/"
                )
            ).data,
        enabled,
    });
};
