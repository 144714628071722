import { Form, Select } from "antd";

const RankSelect = ({}) => {
    return (
        <Form.Item style={{ margin: 0 }} name="rank" preserve={false}>
            <Select
                placeholder="Select a rank"
                options={[
                    { value: "Feeds", label: "Feeds" },
                    { value: "Priority - High", label: "Priority - High" },
                    { value: "Priority - Medium", label: "Priority - Medium" },
                    { value: "Priority - Low", label: "Priority - Low" },
                ]}
            />
        </Form.Item>
    );
};

export default RankSelect;
