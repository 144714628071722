import { Form, Input } from "antd";

import { ConstraintRecordProps } from "../columnInfo";

const MaximumInput = ({ constraintName, constraintId }: ConstraintRecordProps) => {
    const minimumName = [constraintName, `constraint_id_${constraintId}`, "minimum"];
    const maximumName = [constraintName, `constraint_id_${constraintId}`, "maximum"];
    const includeNullName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "include_null",
    ];

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={maximumName}
            preserve={false}
            rules={[
                ({ getFieldValue }) => ({
                    validator: async (_, value) => {
                        const minimumValue = getFieldValue(minimumName)
                            ? Number(getFieldValue(minimumName))
                            : null;
                        const maximumValue = value ? Number(value) : null;
                        const includeNullValue = Boolean(
                            getFieldValue(includeNullName)
                        );

                        if (
                            maximumValue == null &&
                            minimumValue == null &&
                            !includeNullValue
                        ) {
                            return Promise.reject(
                                "Please enter at least a minimum or maximum value"
                            );
                        }
                        if (maximumValue < 0) {
                            return Promise.reject("Maximum must be positive");
                        }
                        if (
                            maximumValue != null &&
                            minimumValue != null &&
                            maximumValue < minimumValue
                        ) {
                            return Promise.reject(
                                "Maximum must be same or more than minimum"
                            );
                        }
                    },
                }),
            ]}
        >
            <Input type="number" />
        </Form.Item>
    );
};

export default MaximumInput;
