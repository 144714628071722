import { InfoCircleOutlined } from "@ant-design/icons";
import { Select, Switch, Button, Form, Popconfirm, Tooltip } from "antd";

import { ConstraintNameEnum } from "reactQuery/hooks/apiTypes";

import AdditionalNotesInput from "./AdditionalNotesInput";
import CountiesSelect from "./CountiesSelect";
import CountriesSelect from "./CountriesSelect";
import DateInput from "./DateInput";
import IncludeNullCheckbox from "./IncludeNullCheckbox";
import IndicatorCascader from "./IndicatorCascader";
import IndicatorTagsSelect from "./IndicatorTagsSelect";
import InitiativeTypesSelect from "./InitiativeTypesSelect";
import MaximumInput from "./MaximumInput";
import MaximumOnlyInput from "./MaximumOnlyInput";
import MinimumInput from "./MinimumInput";
import OwnersSelect from "./OwnersSelect";
import OwnerTypesSelect from "./OwnerTypesSelect";
import ProfilesSelect from "./ProfilesSelect";
import RankSelect from "./RankSelect";
import StatesSelect from "./StatesSelect";
import TimingStatusesSelect from "./TimingStatusesSelect";

export interface ConstraintRecordProps {
    key?: number;
    constraintId: number;
    constraintName: ConstraintNameEnum;
}

export const numberColumn = {
    title: "#",
    dataIndex: "rowNumber",
    key: "rowNumber",
    width: "5%",
};

export const countriesColumn = {
    title: "Countries",
    dataIndex: "countries",
    key: "countries",
    render: (_, record: ConstraintRecordProps) => {
        return <CountriesSelect {...record} />;
    },
    width: "20%",
};

export const statesColumn = {
    title: "States",
    dataIndex: "states",
    key: "states",
    render: (_, record: ConstraintRecordProps) => {
        return <StatesSelect {...record} />;
    },
    width: "20%",
};

export const countiesColumn = {
    title: "Counties",
    dataIndex: "counties",
    key: "counties",
    render: (_, record: ConstraintRecordProps) => {
        return <CountiesSelect {...record} />;
    },
    width: "20%",
};

export const ownersColumn = {
    title: "Owners",
    dataIndex: "owners",
    key: "owners",
    render: (_, record: ConstraintRecordProps) => {
        return <OwnersSelect {...record} />;
    },
    width: "20%",
};

export const ownerTypesColumn = {
    title: "Owner Types",
    dataIndex: "ownerTypes",
    key: "ownerTypes",
    render: (_, record: ConstraintRecordProps) => {
        return <OwnerTypesSelect {...record} />;
    },
    width: "20%",
};

export const populationMinimumColumn = {
    title: "Minimum",
    dataIndex: "populationMinimum",
    key: "populationMinimum",
    render: (_, record: ConstraintRecordProps) => {
        return <MinimumInput {...record} />;
    },
    width: "10%",
};

export const populationMaximumColumn = {
    title: "Maximum",
    dataIndex: "populationMaximum",
    key: "populationMaximum",
    render: (_, record: ConstraintRecordProps) => {
        return <MaximumInput {...record} />;
    },
    width: "10%",
};

export const initiativeTypesColumn = {
    title: "Initiative Types",
    dataIndex: "initiativeTypes",
    key: "initiativeTypes",
    render: (_, record: ConstraintRecordProps) => {
        return <InitiativeTypesSelect {...record} />;
    },
    width: "20%",
};

export const timingStatusesColumn = {
    title: "Timing Statuses",
    dataIndex: "timingStatus",
    key: "timingStatus",
    render: (_, record: ConstraintRecordProps) => {
        return <TimingStatusesSelect {...record} />;
    },
    width: "20%",
};

export const indicatorTagsColumn = {
    title: "Indicator Tags",
    dataIndex: "indicatorTags",
    key: "indicatorTags",
    render: (_, record: ConstraintRecordProps) => {
        return <IndicatorTagsSelect {...record} />;
    },
    width: "20%",
};

export const budgetMinimumColumn = {
    title: "Minimum $",
    dataIndex: "budgetMinimum",
    key: "budgetMinimum",
    render: (_, record: ConstraintRecordProps) => {
        return <MinimumInput {...record} />;
    },
    width: "10%",
};

export const budgetMaximumColumn = {
    title: "Maximum $",
    dataIndex: "budgetMaximum",
    key: "budgetMaximum",
    render: (_, record: ConstraintRecordProps) => {
        return <MaximumInput {...record} />;
    },
    width: "10%",
};

export const maximumMonthsColumn = {
    title: "Maximum Months",
    dataIndex: "maximumMonths",
    key: "maximumMonths",
    render: (_, record: ConstraintRecordProps) => {
        return <MaximumOnlyInput {...record} />;
    },
    width: "20%",
};

export const minimumMGDColumn = {
    title: "Minimum MGD",
    dataIndex: "minimumMGD",
    key: "minimumMGD",
    render: (_, record: ConstraintRecordProps) => {
        return <MinimumInput {...record} />;
    },
    width: "10%",
};

export const maximumMGDColumn = {
    title: "Maximum MGD",
    dataIndex: "maximumMGD",
    key: "maximumMGD",
    render: (_, record: ConstraintRecordProps) => {
        return <MaximumInput {...record} />;
    },
    width: "10%",
};

export const startDisplayDateColumn = {
    title: "Start Display Date",
    dataIndex: "startDisplayDate",
    key: "startDisplayDate",
    render: (_, record: ConstraintRecordProps) => {
        return <DateInput {...record} />;
    },
    width: "20%",
};

export const requirementTypeColumn = {
    title: "Requirement Type",
    dataIndex: "requirementType",
    key: "requirementType",
    render: (_, { constraintName, constraintId }: ConstraintRecordProps) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={[
                    constraintName,
                    `constraint_id_${constraintId}`,
                    "constraint_type",
                ]}
                preserve={false}
                rules={[
                    {
                        required: true,
                        message: "Please select a requirement type",
                    },
                ]}
            >
                <Select
                    style={{ width: "100%" }}
                    options={[
                        { value: "Include", label: "Include" },
                        { value: "Exclude", label: "Exclude" },
                    ]}
                />
            </Form.Item>
        );
    },
    width: "10%",
};

export const filterTypeColumn = {
    title: "Filter Type",
    dataIndex: "filterType",
    key: "filterType",
    render: (_, { constraintName, constraintId }: ConstraintRecordProps) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                name={[constraintName, `constraint_id_${constraintId}`, "filter_type"]}
                preserve={false}
                rules={[
                    {
                        required: true,
                        message: "Please select a filter type",
                    },
                ]}
            >
                <Select
                    style={{ width: "100%" }}
                    options={[
                        { value: "Requirement", label: "Requirement" },
                        { value: "Priority - High", label: "Priority - High" },
                        { value: "Priority - Medium", label: "Priority - Medium" },
                        { value: "Priority - Low", label: "Priority - Low" },
                    ]}
                />
            </Form.Item>
        );
    },
    width: "10%",
};

export const profilesColumn = {
    title: "Users",
    dataIndex: "users",
    key: "users",
    render: (_, record: ConstraintRecordProps) => {
        return <ProfilesSelect {...record} />;
    },
};

export const nullColumn = {
    title: () => {
        return (
            <Tooltip
                title="When checked, display results on platform where constraint value is null, regardless of requirement type."
                placement="top"
            >
                Include Null
                <InfoCircleOutlined style={{ marginLeft: "5px" }} />
            </Tooltip>
        );
    },
    dataIndex: "include_null",
    key: "include_null",
    render: (_, record: ConstraintRecordProps) => {
        return <IncludeNullCheckbox {...record} />;
    },
    width: "5%",
};

export const copyColumn = {
    title: () => {
        return (
            <Tooltip
                title="Toggle this switch and click 'Submit' below to copy this constraint to the other intelligence scopes."
                placement="top"
            >
                Copy
                <InfoCircleOutlined style={{ marginLeft: "5px" }} />
            </Tooltip>
        );
    },
    dataIndex: "copy",
    key: "copy",
    render: (_, { constraintName, constraintId }: ConstraintRecordProps) => {
        return (
            <Form.Item
                style={{ margin: 0 }}
                valuePropName="checked"
                name={[constraintName, `constraint_id_${constraintId}`, "copy"]}
                preserve={false}
            >
                <Switch />
            </Form.Item>
        );
    },
    width: "5%",
};

export const deleteConstraintColumn = (onDelete: any) => {
    return {
        title: "Delete",
        dataIndex: "delete",
        key: "delete",
        render: (_, { constraintId }: ConstraintRecordProps) => {
            return (
                <Button size="small" danger onClick={() => onDelete(constraintId)}>
                    Delete
                </Button>
            );
        },
        width: "5%",
    };
};

export const indicatorCascaderColumn = {
    title: "Indicator",
    dataIndex: "indicator",
    key: "indicator",
    render: () => {
        return <IndicatorCascader />;
    },
    width: "40%",
};

export const rankColumn = {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
    render: () => {
        return <RankSelect />;
    },
    width: "10%",
};

export const additionalNotesColumn = {
    title: "Additional Notes",
    dataIndex: "additionalNotes",
    key: "additionalNotes",
    render: () => {
        return <AdditionalNotesInput />;
    },
    width: "20%",
};

export const deleteInteligenceScopeColumn = (
    open: boolean,
    setOpen: (flag: boolean) => void,
    onDeleteButtonClick: () => void
) => {
    return {
        title: "Delete",
        dataIndex: "delete",
        key: "delete",
        render: () => {
            return (
                <Popconfirm
                    title="Are you sure you want to delete this intelligence scope and all of its associated constraints?"
                    placement="left"
                    open={open}
                    onConfirm={() => {
                        onDeleteButtonClick();
                        setOpen(false);
                    }}
                    onCancel={() => setOpen(false)}
                    okText="Delete"
                    okType="danger"
                >
                    <Button size="small" danger onClick={() => setOpen(true)}>
                        Delete
                    </Button>
                </Popconfirm>
            );
        },
        width: "5%",
    };
};
