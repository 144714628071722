import { Checkbox, Form, Input } from "antd";

import { ConstraintRecordProps } from "../columnInfo";

const IncludeNullCheckbox = ({
    constraintName,
    constraintId,
}: ConstraintRecordProps) => {
    const minimumName = [constraintName, `constraint_id_${constraintId}`, "minimum"];
    const maximumName = [constraintName, `constraint_id_${constraintId}`, "maximum"];
    const countiesName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "demographics",
    ];
    const ownersName = [constraintName, `constraint_id_${constraintId}`, "owners"];
    const ownerTypesName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "owner_types",
    ];
    const initiativeTypesName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "initiative_types",
    ];
    const timingStatusesName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "statuses",
    ];
    const indicatorTagsName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "indicator_tags",
    ];
    const displayStartDateName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "display_start_date",
    ];

    const fieldsToValidate = [
        minimumName,
        maximumName,
        countiesName,
        ownersName,
        ownerTypesName,
        initiativeTypesName,
        timingStatusesName,
        indicatorTagsName,
        displayStartDateName,
    ];

    return (
        <Form.Item
            style={{ margin: 0 }}
            valuePropName="checked"
            name={[constraintName, `constraint_id_${constraintId}`, "include_null"]}
            preserve={false}
            rules={[
                ({ validateFields }) => ({
                    validator: async () => {
                        // setTimeout use is required to avoid outOfDate=true error
                        // upon saving the form (see https://github.com/react-component/field-form/issues/196)
                        setTimeout(() => {
                            validateFields(fieldsToValidate);
                        });
                    },
                }),
            ]}
        >
            <Checkbox />
        </Form.Item>
    );
};

export default IncludeNullCheckbox;
