import { ConstraintNameEnum } from "reactQuery/hooks/apiTypes";

export const validateMultiSelectWithIncludeNull = (
    constraintName: ConstraintNameEnum,
    constraintId: number,
    getFieldValue: Function,
    message: string
) => {
    return {
        // If the multi select value is empty and include null is unchecked, show an error message
        // If include null is checked, the multi select value can be empty
        validator: async (_, value: Array<any>) => {
            const includeNullName = [
                constraintName,
                `constraint_id_${constraintId}`,
                "include_null",
            ];
            const includeNullValue = Boolean(getFieldValue(includeNullName));

            const isMultiSelectEmpty = value == null || value?.length == 0;
            if (isMultiSelectEmpty && !includeNullValue) {
                return Promise.reject(message);
            }
        },
    };
};
