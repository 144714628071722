import { RankEnum } from "reactQuery/hooks/apiTypes";

export const intelligenceScopeHeader = (
    industry: string,
    indicator: string,
    rank: RankEnum,
    index: number
): string => {
    let header = `Intelligence Scope ${index + 1}`;
    if (industry && indicator) {
        header += ` - ${industry} - ${indicator} - ${rank}`;
    } else {
        header += " - (New)";
    }
    return header;
};
