import { Form, Select } from "antd";

import { Country } from "reactQuery/hooks/apiTypes";
import { useGetCountries } from "reactQuery/hooks/useCountries";

import { ConstraintRecordProps } from "../columnInfo";

const CountriesSelect = ({ constraintName, constraintId }: ConstraintRecordProps) => {
    const countriesQuery = useGetCountries();

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={[constraintName, `constraint_id_${constraintId}`, "countries"]}
            rules={[{ required: true, message: "Please select one or more countries" }]}
            preserve={false}
        >
            <Select
                placeholder="Select one or more countries"
                showSearch
                style={{ width: "100%" }}
                mode="multiple"
                allowClear={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    const country = option.title.toLowerCase();
                    return country.includes(input.toLowerCase());
                }}
            >
                {countriesQuery.isLoading ? (
                    <Select.Option value="disabled" disabled>
                        Loading...
                    </Select.Option>
                ) : countriesQuery.isSuccess ? (
                    countriesQuery.data.map((country: Country) => {
                        return (
                            <Select.Option
                                key={country.id}
                                value={country.id}
                                title={country.name}
                            >
                                {country.name}
                            </Select.Option>
                        );
                    })
                ) : (
                    <Select.Option value="disabled" disabled>
                        There was a problem loading country options
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default CountriesSelect;
