import { SearchPlanFormIntelligenceScopeListItem } from "reactQuery/hooks/apiTypes";

export const intelligenceScopesSorter = (
    a: SearchPlanFormIntelligenceScopeListItem,
    b: SearchPlanFormIntelligenceScopeListItem
) => {
    // ensure that new intelligence scopes are always at the bottom
    if (!a.industry) return 1;

    // sort intelligence scopes alphabetically by industry
    return a.industry.localeCompare(b.industry);
};
