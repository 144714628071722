import { Form, Input } from "antd";

import { ConstraintRecordProps } from "../columnInfo";

const MaximumOnlyInput = ({ constraintName, constraintId }: ConstraintRecordProps) => {
    const maximumName = [constraintName, `constraint_id_${constraintId}`, "maximum"];
    const includeNullName = [
        constraintName,
        `constraint_id_${constraintId}`,
        "include_null",
    ];

    return (
        <Form.Item
            style={{ margin: 0 }}
            name={maximumName}
            preserve={false}
            rules={[
                ({ getFieldValue }) => ({
                    validator: async (_, value) => {
                        const maximumValue = value ? Number(value) : null;
                        const includeNullValue = Boolean(
                            getFieldValue(includeNullName)
                        );

                        if (maximumValue == null && !includeNullValue) {
                            return Promise.reject("Please enter a maximum value");
                        }
                        if (maximumValue < 0) {
                            return Promise.reject("Maximum must be positive");
                        }
                    },
                }),
            ]}
        >
            <Input type="number" />
        </Form.Item>
    );
};

export default MaximumOnlyInput;
