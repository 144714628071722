import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Collapse, Layout, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SearchPlanFormIntelligenceScopeListItem } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetSearchPlanOverview } from "reactQuery/hooks/useSearchPlanForm";
import { searchPlanFormKeys } from "reactQuery/keys";

import CopyConstraintsModal from "../CopyConstraintsModal";
import { intelligenceScopeHeader } from "../headerHelper";
import IntelligenceScopeForm from "../IntelligenceScopeForm";
import { intelligenceScopesSorter } from "../sortHelper";
export const IntelligenceScopesPathname = "/intelligence_scopes/:search_plan_id";

const { Content } = Layout;
const { Panel } = Collapse;

const IntelligenceScopesPage = () => {
    const { search_plan_id: searchPlanId } = useParams();
    const searchPlanOverviewQuery = useGetSearchPlanOverview(searchPlanId);

    const whoamiQuery = useGetWhoami();
    const isStaff = whoamiQuery.data?.user.is_staff ?? false;

    const [intelligenceScopes, setIntelligenceScopes] = useState<
        SearchPlanFormIntelligenceScopeListItem[]
    >([]);

    const [defaultActiveKey, setDefaultActiveKey] = useState<number>(undefined);

    const addIntelligenceScopeButtonDisabled = intelligenceScopes.some(
        (intelligenceScope) => intelligenceScope.id < 0
    );

    useEffect(() => {
        if (searchPlanOverviewQuery.isSuccess) {
            setIntelligenceScopes(searchPlanOverviewQuery.data.intelligence_scopes);
        }
    }, [searchPlanOverviewQuery.data, searchPlanOverviewQuery.isSuccess]);

    const queryClient = useQueryClient();

    const addIntelligenceScope = () => {
        const temporaryIndex = -(intelligenceScopes.length + 1);
        queryClient.removeQueries(
            searchPlanFormKeys.intelligenceScopeDetail(temporaryIndex)
        );
        setIntelligenceScopes([
            ...intelligenceScopes,
            { id: temporaryIndex, industry: undefined, indicator: undefined },
        ]);
        setDefaultActiveKey(temporaryIndex);
    };

    const deleteTemporaryIntelligenceScope = (id: number) => {
        const newIntelligenceScopes = intelligenceScopes.filter(
            (intelligenceScope) => intelligenceScope.id !== id
        );
        setIntelligenceScopes(newIntelligenceScopes);
    };

    const page = (
        <Layout>
            <Content style={{ margin: "20px" }}>
                {searchPlanOverviewQuery.isSuccess ? (
                    <h1>
                        Intelligence Scopes for {searchPlanOverviewQuery.data.team.name}
                    </h1>
                ) : (
                    <h1>...</h1>
                )}

                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    {intelligenceScopes
                        .sort(intelligenceScopesSorter)
                        .map((intelligenceScope, index) => {
                            const header = intelligenceScopeHeader(
                                intelligenceScope.industry,
                                intelligenceScope.indicator,
                                intelligenceScope.rank,
                                index
                            );

                            return (
                                <Collapse
                                    defaultActiveKey={defaultActiveKey}
                                    key={intelligenceScope.id}
                                >
                                    <Panel header={header} key={intelligenceScope.id}>
                                        <IntelligenceScopeForm
                                            key={intelligenceScope.id}
                                            intelligenceScope={intelligenceScope}
                                            deleteTemporaryIntelligenceScope={
                                                deleteTemporaryIntelligenceScope
                                            }
                                            setDefaultActiveKey={setDefaultActiveKey}
                                        />
                                    </Panel>
                                </Collapse>
                            );
                        })}

                    <Tooltip
                        title={
                            addIntelligenceScopeButtonDisabled
                                ? "Please save the intelligence scope being edited before adding a new one"
                                : ""
                        }
                    >
                        <Button
                            type="dashed"
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            disabled={addIntelligenceScopeButtonDisabled}
                            onClick={() => addIntelligenceScope()}
                        >
                            Add Intelligence Scope
                        </Button>
                    </Tooltip>
                </Space>
            </Content>
            <CopyConstraintsModal />
        </Layout>
    );

    if (!whoamiQuery.isSuccess) return null;

    return isStaff ? page : <h1>Unauthorized</h1>;
};

export default IntelligenceScopesPage;
