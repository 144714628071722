import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Table } from "antd";
import { useEffect, useState } from "react";

import { additionalColors } from "constants/colors";
import {
    ConstraintNameEnum,
    SearchPlanFormCountryConstraint,
    SearchPlanFormCountyConstraint,
    SearchPlanFormDisplayStartDateConstraint,
    SearchPlanFormIndicatorTagConstraint,
    SearchPlanFormInitiativeTypeConstraint,
    SearchPlanFormMGDConstraint,
    SearchPlanFormOwnerConstraint,
    SearchPlanFormOwnerTypeConstraint,
    SearchPlanFormPopulationConstraint,
    SearchPlanFormProjectBudgetConstraint,
    SearchPlanFormSourceTimeframeConstraint,
    SearchPlanFormStateConstraint,
    SearchPlanFormTimingStatusConstraint,
} from "reactQuery/hooks/apiTypes";
import {
    useDeleteConstraint,
    useGetIntelligenceScopeDetail,
} from "reactQuery/hooks/useSearchPlanForm";
import { useSnackStore } from "stores/zustandStore";

import {
    budgetMaximumColumn,
    budgetMinimumColumn,
    copyColumn,
    countiesColumn,
    countriesColumn,
    deleteConstraintColumn,
    filterTypeColumn,
    indicatorTagsColumn,
    initiativeTypesColumn,
    maximumMGDColumn,
    maximumMonthsColumn,
    minimumMGDColumn,
    nullColumn,
    numberColumn,
    ownersColumn,
    ownerTypesColumn,
    populationMaximumColumn,
    populationMinimumColumn,
    requirementTypeColumn,
    startDisplayDateColumn,
    statesColumn,
    timingStatusesColumn,
    profilesColumn,
} from "../columnInfo";

const { Panel } = Collapse;

export const constraintDeletedSuccessfullyMessage = "Constraint deleted successfully";
export const constraintDeleteErrorMessage = "Error deleting Constraint";

interface SearchPlanFormConstraint
    extends SearchPlanFormCountryConstraint,
        SearchPlanFormStateConstraint,
        SearchPlanFormCountyConstraint,
        SearchPlanFormIndicatorTagConstraint,
        SearchPlanFormInitiativeTypeConstraint,
        SearchPlanFormMGDConstraint,
        SearchPlanFormOwnerConstraint,
        SearchPlanFormOwnerTypeConstraint,
        SearchPlanFormPopulationConstraint,
        SearchPlanFormProjectBudgetConstraint,
        SearchPlanFormSourceTimeframeConstraint,
        SearchPlanFormTimingStatusConstraint,
        SearchPlanFormDisplayStartDateConstraint {}

const ConstraintCollapse = ({
    constraintName,
    intelligenceScopeId,
    setTrackedChanges,
    trackedChanges,
}: {
    constraintName: ConstraintNameEnum;
    intelligenceScopeId: number;
    setTrackedChanges: (value: Object) => void;
    trackedChanges: Object;
}) => {
    const intelligenceScopeDetailQuery =
        useGetIntelligenceScopeDetail(intelligenceScopeId);

    const [dataSource, setDataSource] = useState([]);

    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const updateUIandTrackedChanges = (id: number) => {
        // update the dataSource used by the table
        setDataSource(
            dataSource.filter((constraint) => constraint.constraintId !== id)
        );

        // remove the constraint from trackedChanges
        delete trackedChanges?.[constraintName]?.[`constraint_id_${id}`];
        setTrackedChanges(trackedChanges);
    };

    const handleDeleteConstraint = (constraintId: number) => {
        updateUIandTrackedChanges(constraintId);
        displaySuccessSnack({
            message: constraintDeletedSuccessfullyMessage,
        });
    };

    const deleteConstraintQuery = useDeleteConstraint(constraintName);
    const deleteConstraint = (constraintId: number) => {
        if (constraintId < 0) {
            return handleDeleteConstraint(constraintId);
        }
        deleteConstraintQuery.mutate(constraintId, {
            onSuccess: () => {
                handleDeleteConstraint(constraintId);
            },
            onError: (e) => {
                displayErrorSnack({
                    message: constraintDeleteErrorMessage,
                });
            },
        });
    };

    let columns: any[];
    if (
        constraintName === ConstraintNameEnum.Countries ||
        constraintName === ConstraintNameEnum.States
    ) {
        // Countries and States constraints do not have the "Include Null" column
        columns = [
            numberColumn,
            requirementTypeColumn,
            filterTypeColumn,
            profilesColumn,
            copyColumn,
            deleteConstraintColumn(deleteConstraint),
        ];
    } else {
        columns = [
            numberColumn,
            requirementTypeColumn,
            filterTypeColumn,
            profilesColumn,
            nullColumn,
            copyColumn,
            deleteConstraintColumn(deleteConstraint),
        ];
    }

    let specialColumns = [],
        constraintKey: string,
        panelHeader: string;
    switch (constraintName) {
        case ConstraintNameEnum.Countries:
            specialColumns = [countriesColumn];
            constraintKey = "country_constraints";
            panelHeader = "Countries";
            break;
        case ConstraintNameEnum.States:
            specialColumns = [statesColumn];
            constraintKey = "state_constraints";
            panelHeader = "States";
            break;
        case ConstraintNameEnum.Counties:
            specialColumns = [countiesColumn];
            constraintKey = "county_constraints";
            panelHeader = "Counties";
            break;
        case ConstraintNameEnum.Owners:
            specialColumns = [ownersColumn];
            constraintKey = "owner_constraints";
            panelHeader = "Owners";
            break;
        case ConstraintNameEnum.OwnerTypes:
            specialColumns = [ownerTypesColumn];
            constraintKey = "owner_type_constraints";
            panelHeader = "Owner Types";
            break;
        case ConstraintNameEnum.Population:
            specialColumns = [populationMinimumColumn, populationMaximumColumn];
            constraintKey = "population_constraints";
            panelHeader = "Population";
            break;
        case ConstraintNameEnum.InitiativeTypes:
            specialColumns = [initiativeTypesColumn];
            constraintKey = "initiativetype_constraints";
            panelHeader = "Initiative Types";
            break;
        case ConstraintNameEnum.TimingStatuses:
            specialColumns = [timingStatusesColumn];
            constraintKey = "timing_status_constraints";
            panelHeader = "Timing Status";
            break;
        case ConstraintNameEnum.IndicatorTags:
            specialColumns = [indicatorTagsColumn];
            constraintKey = "indicatortag_constraints";
            panelHeader = "Indicator Tags";
            break;
        case ConstraintNameEnum.ProjectBudget:
            specialColumns = [budgetMinimumColumn, budgetMaximumColumn];
            constraintKey = "project_budget_constraints";
            panelHeader = "Project Budget";
            break;
        case ConstraintNameEnum.SourceDocumentDate:
            specialColumns = [maximumMonthsColumn];
            constraintKey = "source_timeframe_constraints";
            panelHeader = "Source Document Date";
            break;
        case ConstraintNameEnum.DesignFlowMgd:
            specialColumns = [minimumMGDColumn, maximumMGDColumn];
            constraintKey = "mgd_constraints";
            panelHeader = "Design Flow MGD";
            break;
        case ConstraintNameEnum.DisplayStartDate:
            specialColumns = [startDisplayDateColumn];
            constraintKey = "display_start_date_constraints";
            panelHeader = "Display Date";
            break;
    }

    columns.splice(1, 0, ...specialColumns);

    useEffect(() => {
        if (intelligenceScopeDetailQuery.isSuccess) {
            const constraints = intelligenceScopeDetailQuery.data[constraintKey];
            setDataSource(
                constraints.map(
                    (constraint: SearchPlanFormConstraint, index: number) => {
                        return {
                            rowNumber: index + 1,
                            key: constraint.id,
                            constraintId: constraint.id,
                            constraintName,
                        };
                    }
                )
            );
        }
    }, [intelligenceScopeDetailQuery.isSuccess, intelligenceScopeDetailQuery.data]);

    const addRow = () => {
        let rowNumber = dataSource.length + 1;
        let temporaryId = -rowNumber;
        const newData = {
            rowNumber: rowNumber,
            key: temporaryId,
            constraintId: temporaryId,
            constraintName,
        };
        setDataSource([...dataSource, newData]);
    };

    return (
        <Collapse>
            <Panel header={panelHeader} key={constraintKey}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    pagination={false}
                />

                <Button
                    type="dashed"
                    style={{ width: "100%", color: additionalColors.green500 }}
                    icon={<PlusOutlined />}
                    onClick={() => addRow()}
                >
                    Add row
                </Button>
            </Panel>
        </Collapse>
    );
};

export default ConstraintCollapse;
